import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    beforeEnter() {
      window.location.href = "https://taqui-co.com";
    },
  },
  {
    path: "/init/:idEvento",
    name: "InitTicket",
    component: () => import("../views/InitView.vue"),
    meta: {
      title: "Inicio | VaQuita",
    },
  },
  {
    path: "/event/:idEvento",
    name: "Event",
    component: () => import("../views/EventView.vue"),
    children: [
      {
        path: "/typeTicket/:idEvento",
        name: "TypeTicket",
        component: () => import("../views/TypeTicketView.vue"),
        meta: {
          title: "Tipo de entrada | VaQuita",
        },
      },
      {
        path: "/location/:idEvento",
        name: "Location",
        component: () => import("../views/LocationView.vue"),
        meta: {
          title: "Localidad | VaQuita",
        },
      },
      {
        path: "/cart/:idEvento",
        name: "Cart",
        component: () => import("../views/CartView.vue"),
        meta: {
          title: "Resumen | VaQuita",
        },
      },
    ],
  },
  {
    path: "/auth/:idReserva",
    name: "Auth",
    component: () => import("../views/AuthView.vue"),
    meta: {
      title: "Inicia sesión | VaQuita",
    },
  },
  {
    path: "/delivery/:idReserva",
    name: "Delivery",
    component: () => import("../views/DeliveryView.vue"),
    meta: {
      title: "Entrega | VaQuita",
    },
  },
  {
    path: "/checkout/:idReserva",
    name: "Checkout",
    component: () => import("../views/CheckoutView.vue"),
    meta: {
      title: "Confirmación | VaQuita",
    },
  },
  //Rutas para abonos
  {
    path: "/seasonTicketInit/:idGrupo",
    name: "SeasonTicketInit",
    component: () => import("../views/SeasonTicketInitView.vue"),
    meta: {
      title: "Inicio | VaQuita",
    },
  },
  {
    path: "/seasonTicket/:idGrupo",
    name: "seasonTicket",
    component: () => import("../views/SeasonTicketView.vue"),
    children: [
      {
        path: "/seasonTypeTicket/:idGrupo",
        name: "SeasonTypeTicket",
        component: () => import("../views/SeasonTypeTicketView.vue"),
        meta: {
          title: "Tipo de entrada | TaquiShop",
        },
      },
      {
        path: "/seasonTypeBook/:idGrupo",
        name: "SeasonTypeBook",
        component: () => import("../views/SeasonTypeBookView.vue"),
        meta: {
          title: "Modalidad de compra | TaquiShop",
        },
      },
      {
        path: "/seasonLocationAuto/:idGrupo",
        name: "SeasonLocationAuto",
        component: () => import("../views/SeasonLocationAutoView.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/seasonLocation/:idGrupo",
        name: "SeasonLocation",
        component: () => import("../views/SeasonLocationView.vue"),
        meta: {
          title: "Localidad | TaquiShop",
        },
      },
      {
        path: "/seasonCart/:idGrupo",
        name: "SeasonCart",
        component: () => import("../views/SeasonCartView.vue"),
        meta: {
          title: "Resumen | TaquiShop",
        },
      },
    ],
  },
  //Rutas para abonos
  {
    path: "/seasonAuth/:idAbono",
    name: "SeasonAuth",
    component: () => import("../views/SeasonAuthView.vue"),
    meta: {
      title: "Inicia sesión | TaquiShop",
    },
  },
  {
    path: "/seasonDelivery/:idAbono",
    name: "SeasonDelivery",
    component: () => import("../views/SeasonDeliveryView.vue"),
    meta: {
      title: "Entrega | TaquiShop",
    },
  },
  {
    path: "/seasonCheckout/:idAbono",
    name: "SeasonCheckout",
    component: () => import("../views/SeasonCheckoutView.vue"),
    meta: {
      title: "Confirmación | TaquiShop",
    },
  },
  {
    path: "/seasonTicketDownload/:idAbono",
    name: "SeasonTicketDownload",
    component: () => import("../views/SeasonTicketDownloadView.vue"),
    meta: {
      title: "Descarga tus entradas | TaquiShop",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    beforeEnter() {
      window.location.href = "https://taqui-co.com";
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    beforeEnter() {
      //window.location.href = "https://taqui-co.com";
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? " VaQuita";
});

export default router;
